import React from "react"

import SEO from "../components/seo"
import ButtonLink from "../components/buttonLink"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <div className="pageContent">
      <h1 className="base__pageTitle">Page not found</h1>
      <p>The page you are looking for doesn't exist.</p>
      <ButtonLink buttonText="Return Home" linkTo="/"></ButtonLink>
    </div>
  </div>
)

export default NotFoundPage
