import React from "react"
import { Link } from "gatsby"

const ButtonLink = ({
  buttonText = "button text",
  linkTo = "#",
  className = "",
}) => {
  return (
    <Link to={linkTo} className={`buttonLink ${className}`}>
      <div>{buttonText}</div>
    </Link>
  )
}

export default ButtonLink
